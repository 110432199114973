import React from 'react';
import Container from '../Container';

const RegionCard = ({ title, children }) => (
  <article className="rounded shadow p-7 mb-4">
    <h4 className="text-4xl text-black mb-5 pb-3 border-b border-sp-primary-light-grey">{title}</h4>
    <ul>{children}</ul>
  </article>
);

const AllPaymentIntegrationsSection = () => (
  <section>
    <Container className="mb-24 ">
      <h3 className="text-center text-4xl lg:pt-7 md:pt-0 mb-2 font-light text-sp-text-grey">
        All Supported Payment Providers
      </h3>

      <p className="text-lg text-center mb-10">
        We support many additional providers via our third party payment integration partners.
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 text-md text-dark mx-6 leading-5">
        <div>
          <RegionCard title="USA">
            <li>Acapture</li>
            <li>Adyen</li>
            <li>Authorize.Net</li>
            <li>Bambora North America (formerly Beanstream)</li>
            <li>BluePay</li>
            <li>BlueSnap</li>
            <li>Braintree</li>
            <li>BridgePay</li>
            <li>CardConnect</li>
            <li>Cardstream</li>
            <li>CASHNet</li>
            <li>Cayan</li>
            <li>CenPOS</li>
            <li>Checkout.com</li>
            <li>CommerceHub</li>
            <li>Creditcall</li>
            <li>PayFacto (formerly CT-Payment)</li>
            <li>CyberSource</li>
            <li>CyberSource REST</li>
            <li>Diamond Mind</li>
            <li>DIBS</li>
            <li>Elavon</li>
            <li>Vantiv Express (formerly Element)</li>
            <li>Payeezy (formerly GGE4) Legacy</li>
            <li>Payeezy (formerly GGE4)</li>
            <li>First Pay</li>
            <li>Forte</li>
            <li>First Data Global Gateway</li>
            <li>Ingenico ePayments (formerly GlobalCollect)</li>
            <li>Global Iris</li>
            <li>Global Transport</li>
            <li>Heartland Payment Systems</li>
            <li>iATS Payments</li>
            <li>iVeri</li>
            <li>IXOPAY</li>
            <li>JetPay</li>
            <li>JetPay V2</li>
            <li>Latitude19</li>
            <li>Vantiv eCommerce (formerly Litle)</li>
            <li>Merchant e-Solutions</li>
            <li>Merchant Partners</li>
            <li>Mercury</li>
            <li>Milstar</li>
            <li>Moka</li>
            <li>MONEI</li>
            <li>Mundipagg</li>
            <li>NCR Secure Pay</li>
            <li>NETbilling</li>
            <li>NMI</li>
            <li>Paysafe (formerly Optimal Payments)</li>
            <li>Orbital (Chase Paymentech)</li>
            <li>PAYARC</li>
            <li>PayConex</li>
            <li>PayJunction</li>
            <li>PayTrace</li>
            <li>Payeezy</li>
            <li>Payflow Pro</li>
            <li>Windcave (formerly Payment Express)</li>
            <li>PayPal Commerce Platform</li>
            <li>Paysafe</li>
            <li>Payway</li>
            <li>Priority</li>
            <li>ProPay</li>
            <li>Quantum</li>
            <li>Quickbooks</li>
            <li>Reach</li>
            <li>Realex</li>
            <li>Nuvei (formerly SafeCharge)</li>
            <li>Sage Payment Solutions</li>
            <li>SecureNet</li>
            <li>Shift4</li>
            <li>Stripe</li>
            <li>Stripe Payment Intents</li>
            <li>Spreedly Test</li>
            <li>MasterCard Payment Gateway Service (formerly TNS)</li>
            <li>TransFirst</li>
            <li>TransFirst Transaction Express</li>
            <li>TrustCommerce</li>
            <li>USA ePay</li>
            <li>Vanco</li>
            <li>VisaNet Peru</li>
            <li>WePay</li>
            <li>WorldPay</li>
            <li>Worldpay US</li>
          </RegionCard>
        </div>
        <div>
          <RegionCard title="Great Britain">
            <li>Acapture</li>
            <li>Adyen</li>
            <li>Airwallex</li>
            <li>Barclaycard Smartpay</li>
            <li>Barclays ePDQ Extra Plus</li>
            <li>BlueSnap</li>
            <li>Borgun</li>
            <li>Braintree</li>
            <li>Cardstream</li>
            <li>VR Payment (formerly CardProcess)</li>
            <li>CenPOS</li>
            <li>Checkout.com</li>
            <li>Clearhaus</li>
            <li>Credorax</li>
            <li>CyberSource</li>
            <li>CyberSource REST</li>
            <li>MasterCard Payment Gateway Service (formerly DataCash)</li>
            <li>DIBS</li>
            <li>eWAY Rapid</li>
            <li>Ingenico ePayments (formerly GlobalCollect)</li>
            <li>Global Iris</li>
            <li>iATS Payments</li>
            <li>PayVector</li>
            <li>iVeri</li>
            <li>IXOPAY</li>
            <li>Klarna</li>
            <li>Moka</li>
            <li>MONEI</li>
            <li>Paysafe (formerly Optimal Payments)</li>
            <li>Windcave (formerly Payment Express)</li>
            <li>Paymill</li>
            <li>PayPal Commerce Platform</li>
            <li>Paysafe</li>
            <li>PPRO</li>
            <li>QuickPay</li>
            <li>QuickPay V10</li>
            <li>Rapyd</li>
            <li>Realex</li>
            <li>Nuvei (formerly SafeCharge)</li>
            <li>SagePay</li>
            <li>Stripe</li>
            <li>Stripe Payment Intents</li>
            <li>MasterCard Payment Gateway Service (formerly TNS)</li>
            <li>Wirecard</li>
            <li>WorldPay</li>
          </RegionCard>
          <RegionCard title="Australia">
            <li>Adyen</li>
            <li>Airwallex</li>
            <li>Authorize.Net</li>
            <li>Bambora Asia-Pacific</li>
            <li>BPoint</li>
            <li>Braintree</li>
            <li>Cardstream</li>
            <li>CenPOS</li>
            <li>Checkout.com</li>
            <li>eWAY</li>
            <li>eWAY Rapid</li>
            <li>Fat Zebra</li>
            <li>Ingenico ePayments (formerly GlobalCollect)</li>
            <li>iATS Payments</li>
            <li>IXOPAY</li>
            <li>Merchant Warrior</li>
            <li>MasterCard Payment Gateway Service (formerly MiGS)</li>
            <li>NAB Transact</li>
            <li>Paysafe (formerly Optimal Payments)</li>
            <li>Payflow Pro</li>
            <li>Windcave (formerly Payment Express)</li>
            <li>PayPal Commerce Platform</li>
            <li>Pin Payments</li>
            <li>Qvalent</li>
            <li>Rapyd</li>
            <li>SecurePay Australia</li>
            <li>Stripe</li>
            <li>Stripe Payment Intents</li>
            <li>MasterCard Payment Gateway Service (formerly TNS)</li>
            <li>WorldPay</li>
          </RegionCard>
        </div>
        <div>
          <RegionCard title="France">
            <li>Acapture</li>
            <li>Adyen</li>
            <li>Airwallex</li>
            <li>Barclaycard Smartpay</li>
            <li>BlueSnap</li>
            <li>Braintree</li>
            <li>VR Payment (formerly CardProcess)</li>
            <li>CenPOS</li>
            <li>Checkout.com</li>
            <li>Clearhaus</li>
            <li>Credorax</li>
            <li>CyberSource</li>
            <li>CyberSource REST</li>
            <li>Ingenico ePayments (formerly GlobalCollect)</li>
            <li>Global Iris</li>
            <li>iATS Payments</li>
            <li>IXOPAY</li>
            <li>Klarna</li>
            <li>MONEI</li>
            <li>Ingenico ePayments (formerly Ogone)</li>
            <li>Paymill</li>
            <li>PayPal Commerce Platform</li>
            <li>Paysafe</li>
            <li>PPRO</li>
            <li>QuickPay</li>
            <li>QuickPay V10</li>
            <li>Rapyd</li>
            <li>Realex</li>
            <li>Nuvei (formerly SafeCharge)</li>
            <li>SecurionPay</li>
            <li>Stripe</li>
            <li>Stripe Payment Intents</li>
            <li>MasterCard Payment Gateway Service (formerly TNS)</li>
            <li>Wirecard</li>
            <li>WorldPay</li>
          </RegionCard>
          <RegionCard title="Germany">
            <li>Acapture</li>
            <li>Adyen</li>
            <li>Airwallex</li>
            <li>Barclaycard Smartpay</li>
            <li>BlueSnap</li>
            <li>Borgun</li>
            <li>Braintree</li>
            <li>VR Payment (formerly CardProcess)</li>
            <li>CenPOS</li>
            <li>Checkout.com</li>
            <li>Clearhaus</li>
            <li>Credorax</li>
            <li>CyberSource</li>
            <li>CyberSource REST</li>
            <li>Elavon</li>
            <li>Ingenico ePayments (formerly GlobalCollect)</li>
            <li>iATS Payments</li>
            <li>IXOPAY</li>
            <li>Klarna</li>
            <li>MONEI</li>
            <li>Ingenico ePayments (formerly Ogone)</li>
            <li>Paysafe (formerly Optimal Payments)</li>
            <li>Paymill</li>
            <li>Paysafe</li>
            <li>PPRO</li>
            <li>QuickPay</li>
            <li>QuickPay V10</li>
            <li>Rapyd</li>
            <li>Nuvei (formerly SafeCharge)</li>
            <li>SecurionPay</li>
            <li>Stripe</li>
            <li>Stripe Payment Intents</li>
            <li>MasterCard Payment Gateway Service (formerly TNS)</li>
            <li>Wirecard</li>
            <li>WorldPay</li>
          </RegionCard>
        </div>
      </div>
    </Container>
  </section>
);

export default AllPaymentIntegrationsSection;
