import React from 'react';

const AdyenLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    style={{
      enableBackground: 'new 0 0 101.6 32.9',
    }}
    viewBox="0 0 101.6 32.9"
    {...props}
  >
    <style>{'.st0{fill:#35b454}'}</style>
    <path
      d="M14.3 7.5H.3V12h9.2c.6 0 1 .5 1 1v7.9h-2c-.6 0-1-.5-1-1v-5.7H3.6c-2 0-3.6 1.6-3.6 3.6v4c0 2 1.6 3.6 3.6 3.6h14.3V11.1c0-2-1.6-3.6-3.6-3.6zM31.4 20.9h-2c-.6 0-1-.5-1-1V7.5h-3.9c-2 0-3.6 1.6-3.6 3.6v10.7c0 2 1.6 3.6 3.6 3.6h14.3V0h-7.5l.1 20.9zM52.3 20.9h-2c-.6 0-1-.5-1-1V7.5h-7.5v14.3c0 2 1.6 3.6 3.6 3.6h6.9v2.2H42.1v5.2h14.1c2 0 3.6-1.6 3.6-3.6V7.5h-7.5v13.4zM77.1 7.5H62.7v14.3c0 2 1.6 3.6 3.6 3.6h14.1v-4.5h-9.2c-.6 0-1-.5-1-1V12h2c.6 0 1 .5 1 1v5.7h3.9c2 0 3.6-1.6 3.6-3.6v-4c0-2-1.6-3.6-3.6-3.6zM98 7.5H83.7v17.9h7.5V12h2c.6 0 1 .5 1 1v12.4h7.5V11.1c-.1-2-1.7-3.6-3.7-3.6z"
      className="st0"
    />
  </svg>
);

export default AdyenLogo;
