import React from 'react';

const GMOLogo = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    viewBox="0 0 1762 546"
    {...props}
    style={{
      shapeRendering: 'geometricPrecision',
      textRendering: 'geometricPrecision',
      imageRendering: 'optimizeQuality',
      fillRule: 'evenodd',
      clipRule: 'evenodd',
    }}
  >
    <g>
      <path
        style={{ opacity: 0.978 }}
        fill="#005aac"
        d="M 271.5,-0.5 C 289.833,-0.5 308.167,-0.5 326.5,-0.5C 388.48,2.49478 447.146,17.9948 502.5,46C 525.846,58.0015 546.18,73.8349 563.5,93.5C 561.234,94.3142 558.9,94.8142 556.5,95C 516.5,95.6667 476.5,95.6667 436.5,95C 401.998,68.9353 362.998,54.2687 319.5,51C 266.313,46.8943 217.313,58.8943 172.5,87C 156.246,98.5859 142.746,112.753 132,129.5C 111.764,168.227 114.431,205.227 140,240.5C 161.97,265.795 188.803,283.962 220.5,295C 287.947,316.185 352.947,310.519 415.5,278C 441.764,262.736 461.431,241.403 474.5,214C 414.833,213.667 355.167,213.333 295.5,213C 276.947,209.107 268.78,197.607 271,178.5C 273.488,167.687 279.988,160.52 290.5,157C 393.131,155.511 495.798,155.011 598.5,155.5C 604.303,195.312 594.136,230.312 568,260.5C 543.123,287.05 513.957,307.884 480.5,323C 412.627,351.028 341.96,362.694 268.5,358C 216.472,354.862 166.472,343.196 118.5,323C 80.5344,306.675 48.7011,282.508 23,250.5C 10.5541,232.987 2.72077,213.654 -0.5,192.5C -0.5,183.5 -0.5,174.5 -0.5,165.5C 4.39619,136.709 17.0629,111.709 37.5,90.5C 59.1765,68.8246 83.8431,51.3246 111.5,38C 162.574,15.3973 215.907,2.56401 271.5,-0.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.988 }}
        fill="#015bac"
        d="M 1434.5,-0.5 C 1452.5,-0.5 1470.5,-0.5 1488.5,-0.5C 1549.92,2.6064 1608.26,17.7731 1663.5,45C 1691.57,60.0699 1715.74,79.9032 1736,104.5C 1742.42,113.674 1748.09,123.34 1753,133.5C 1756.79,143.358 1759.63,153.358 1761.5,163.5C 1761.5,173.833 1761.5,184.167 1761.5,194.5C 1758.53,211.124 1752.7,226.791 1744,241.5C 1725.74,268.895 1702.24,290.728 1673.5,307C 1617.87,337.76 1558.21,354.76 1494.5,358C 1420.36,362.985 1349.02,351.319 1280.5,323C 1247.13,307.954 1217.97,287.121 1193,260.5C 1156.74,216.529 1151.74,169.196 1178,118.5C 1195.23,91.9351 1217.4,70.4351 1244.5,54C 1303.47,20.7695 1366.8,2.60283 1434.5,-0.5 Z M 1577.5,79.5 C 1577.97,80.9063 1578.97,81.573 1580.5,81.5C 1580.84,82.3382 1581.51,82.6716 1582.5,82.5C 1583.8,84.8218 1585.8,86.1551 1588.5,86.5C 1603.79,97.9596 1616.96,111.626 1628,127.5C 1630.61,133.053 1633.44,138.387 1636.5,143.5C 1637.72,149.4 1639.39,155.067 1641.5,160.5C 1642.11,166.831 1642.78,173.164 1643.5,179.5C 1642.63,185.818 1641.97,192.151 1641.5,198.5C 1639.6,203.33 1637.93,208.33 1636.5,213.5C 1633.9,218.375 1631.56,223.375 1629.5,228.5C 1628.09,228.973 1627.43,229.973 1627.5,231.5C 1609.88,256.595 1586.88,275.428 1558.5,288C 1503.6,310.354 1447.6,313.52 1390.5,297.5C 1388.79,296.262 1386.79,295.595 1384.5,295.5C 1366.05,288.94 1348.71,280.273 1332.5,269.5C 1331.42,267.457 1329.75,266.124 1327.5,265.5C 1315.39,256.061 1305.06,245.061 1296.5,232.5C 1296.57,230.973 1295.91,229.973 1294.5,229.5C 1277.01,198.377 1276.01,166.71 1291.5,134.5C 1292.4,133.71 1293.07,132.71 1293.5,131.5C 1303.82,113.679 1317.48,98.8458 1334.5,87C 1344.06,81.2689 1353.72,75.7689 1363.5,70.5C 1366.16,70.0652 1368.49,69.0652 1370.5,67.5C 1371.49,67.6716 1372.16,67.3382 1372.5,66.5C 1374.55,66.5979 1376.22,65.9313 1377.5,64.5C 1379.9,64.7406 1381.9,64.0739 1383.5,62.5C 1386.78,62.0719 1389.78,61.0719 1392.5,59.5C 1395.15,59.5905 1397.49,58.9238 1399.5,57.5C 1461.69,42.7161 1521.02,50.0494 1577.5,79.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.984 }}
        fill="#005aac"
        d="M 707.5,3.5 C 720.171,3.33345 732.838,3.50012 745.5,4C 747,5.5 748.5,7 750,8.5C 793.193,76.2405 836.86,143.574 881,210.5C 924.321,143.2 967.654,75.8665 1011,8.5C 1012.5,7 1014,5.5 1015.5,4C 1029.83,3.33333 1044.17,3.33333 1058.5,4C 1096.18,119.887 1133.18,236.054 1169.5,352.5C 1132.93,353.829 1096.43,353.829 1060,352.5C 1042.19,291.408 1024.19,230.408 1006,169.5C 973.305,223.051 940.472,276.551 907.5,330C 889.26,330.825 871.093,330.659 853,329.5C 820.365,276.187 787.698,222.854 755,169.5C 736.856,230.398 718.856,291.398 701,352.5C 664.567,353.829 628.067,353.829 591.5,352.5C 627.616,236.65 664.449,120.983 702,5.5C 703.948,4.89064 705.782,4.22398 707.5,3.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.62 }}
        fill="#5e91c7"
        d="M 1399.5,57.5 C 1397.49,58.9238 1395.15,59.5905 1392.5,59.5C 1394.51,58.0762 1396.85,57.4095 1399.5,57.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.678 }}
        fill="#4986c1"
        d="M 1383.5,62.5 C 1381.9,64.0739 1379.9,64.7406 1377.5,64.5C 1379.1,62.9261 1381.1,62.2594 1383.5,62.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.6 }}
        fill="#6b99cc"
        d="M 1377.5,64.5 C 1376.22,65.9313 1374.55,66.5979 1372.5,66.5C 1373.78,65.0687 1375.45,64.4021 1377.5,64.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.4 }}
        fill="#588cc5"
        d="M 1370.5,67.5 C 1368.49,69.0652 1366.16,70.0652 1363.5,70.5C 1365.32,68.5516 1367.65,67.5516 1370.5,67.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.455 }}
        fill="#6798cb"
        d="M 1577.5,79.5 C 1579.03,79.427 1580.03,80.0937 1580.5,81.5C 1578.97,81.573 1577.97,80.9063 1577.5,79.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.678 }}
        fill="#4986c2"
        d="M 1582.5,82.5 C 1584.85,83.3403 1586.85,84.6736 1588.5,86.5C 1585.8,86.1551 1583.8,84.8218 1582.5,82.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.239 }}
        fill="#77a2d1"
        d="M 1293.5,131.5 C 1293.07,132.71 1292.4,133.71 1291.5,134.5C 1291.29,132.821 1291.95,131.821 1293.5,131.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.4 }}
        fill="#4a82c0"
        d="M 1636.5,143.5 C 1639.55,148.67 1641.22,154.336 1641.5,160.5C 1639.39,155.067 1637.72,149.4 1636.5,143.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.706 }}
        fill="#4682c0"
        d="M 1641.5,198.5 C 1641.2,204.077 1639.53,209.077 1636.5,213.5C 1637.93,208.33 1639.6,203.33 1641.5,198.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.345 }}
        fill="#719ece"
        d="M 1629.5,228.5 C 1629.57,230.027 1628.91,231.027 1627.5,231.5C 1627.43,229.973 1628.09,228.973 1629.5,228.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.443 }}
        fill="#6a9acc"
        d="M 1294.5,229.5 C 1295.91,229.973 1296.57,230.973 1296.5,232.5C 1295.09,232.027 1294.43,231.027 1294.5,229.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.506 }}
        fill="#548ec6"
        d="M 1327.5,265.5 C 1329.75,266.124 1331.42,267.457 1332.5,269.5C 1330.21,268.864 1328.54,267.531 1327.5,265.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.667 }}
        fill="#598fc6"
        d="M 1384.5,295.5 C 1386.79,295.595 1388.79,296.262 1390.5,297.5C 1387.99,297.946 1385.99,297.28 1384.5,295.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.892 }}
        fill="#595656"
        d="M 333.5,438.5 C 337.51,445.513 341.344,452.846 345,460.5C 355.708,488.521 367.541,516.021 380.5,543C 374.509,543.499 368.509,543.666 362.5,543.5C 358.034,530.476 353.034,517.642 347.5,505C 335.819,504.167 324.152,504.334 312.5,505.5C 307.549,517.733 302.883,530.067 298.5,542.5C 295.216,543.657 291.882,543.824 288.5,543C 287.572,542.612 286.905,541.945 286.5,541C 300.435,510.636 313.935,480.136 327,449.5C 328.686,445.448 330.852,441.781 333.5,438.5 Z M 343.5,493.5 C 343.804,494.15 344.137,494.817 344.5,495.5C 339.833,495.833 335.167,496.167 330.5,496.5C 325.449,496.493 320.783,495.827 316.5,494.5C 321.26,484.645 325.76,474.645 330,464.5C 335.05,473.932 339.55,483.599 343.5,493.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.874 }}
        fill="#585655"
        d="M 563.5,438.5 C 565.108,442.59 566.275,446.923 567,451.5C 570.026,482.23 573.526,512.897 577.5,543.5C 572.167,543.5 566.833,543.5 561.5,543.5C 559.796,519.464 557.63,495.464 555,471.5C 541.964,494.238 529.964,517.572 519,541.5C 517.062,542.86 515.396,542.527 514,540.5C 502.667,517.833 491.333,495.167 480,472.5C 476.184,495.991 473.684,519.657 472.5,543.5C 468.5,543.5 464.5,543.5 460.5,543.5C 465.281,508.81 470.281,474.143 475.5,439.5C 476.736,440.246 478.07,440.579 479.5,440.5C 492.109,466.384 505.276,492.051 519,517.5C 533.831,491.171 548.665,464.838 563.5,438.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.877 }}
        fill="#585655"
        d="M 750.5,545.5 C 749.833,545.5 749.167,545.5 748.5,545.5C 724.256,519.749 700.089,493.749 676,467.5C 675.348,492.507 675.514,517.507 676.5,542.5C 672.548,543.272 668.548,543.605 664.5,543.5C 666.494,512.727 667.327,482.06 667,451.5C 667.172,447.129 667.672,442.796 668.5,438.5C 692.453,464.621 716.953,490.288 742,515.5C 742.616,491.126 742.116,466.793 740.5,442.5C 744.884,442.174 749.217,442.508 753.5,443.5C 752.05,454.427 751.217,465.427 751,476.5C 750.5,499.498 750.333,522.498 750.5,545.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.892 }}
        fill="#595656"
        d="M 1428.5,439.5 C 1430.34,440.122 1431.5,441.456 1432,443.5C 1445.62,476.732 1459.78,509.732 1474.5,542.5C 1468.86,543.663 1463.2,543.83 1457.5,543C 1452.8,530.386 1447.8,517.886 1442.5,505.5C 1430.6,504.18 1418.77,504.18 1407,505.5C 1401.4,517.796 1396.9,530.463 1393.5,543.5C 1389.17,543.5 1384.83,543.5 1380.5,543.5C 1394.63,511.239 1408.79,478.906 1423,446.5C 1424.38,443.695 1426.22,441.361 1428.5,439.5 Z M 1439.5,496.5 C 1429.65,496.827 1419.99,496.494 1410.5,495.5C 1415.31,485.216 1420.14,474.883 1425,464.5C 1430.14,475.104 1434.97,485.771 1439.5,496.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.878 }}
        fill="#585655"
        d="M 943.5,545.5 C 941.167,545.5 938.833,545.5 936.5,545.5C 897.011,541.82 879.844,520.153 885,480.5C 890.809,456.699 905.975,443.532 930.5,441C 947.47,438.69 963.636,441.19 979,448.5C 978.219,453.344 976.719,458.01 974.5,462.5C 956.154,445.241 936.154,443.074 914.5,456C 904.135,466.086 899.468,478.419 900.5,493C 900.958,520.625 914.958,535.458 942.5,537.5C 949.692,537.135 956.692,535.802 963.5,533.5C 964.708,520.828 964.708,508.162 963.5,495.5C 968.833,495.5 974.167,495.5 979.5,495.5C 978.986,509.835 978.319,524.168 977.5,538.5C 966.337,542.355 955.004,544.689 943.5,545.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.892 }}
        fill="#595656"
        d="M 1041.5,440.5 C 1045.82,440.102 1048.32,442.102 1049,446.5C 1062.02,478.87 1075.85,510.87 1090.5,542.5C 1084.5,543.833 1078.5,543.833 1072.5,542.5C 1068.23,529.953 1063.39,517.62 1058,505.5C 1046.23,504.18 1034.4,504.18 1022.5,505.5C 1017.44,517.626 1012.94,529.96 1009,542.5C 1004.59,543.632 1000.09,543.799 995.5,543C 1011.24,509.025 1026.57,474.858 1041.5,440.5 Z M 1054.5,493.5 C 1054.5,494.5 1054.5,495.5 1054.5,496.5C 1046.83,496.5 1039.17,496.5 1031.5,496.5C 1029.71,496.785 1028.05,496.452 1026.5,495.5C 1030.9,485.039 1035.56,474.706 1040.5,464.5C 1044.94,474.38 1049.61,484.047 1054.5,493.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.914 }}
        fill="#5a5757"
        d="M 224.5,442.5 C 241.209,442.092 257.876,442.592 274.5,444C 287.158,450.254 291.991,460.421 289,474.5C 287.973,481.536 284.473,487.036 278.5,491C 266.03,497.862 252.696,500.362 238.5,498.5C 239.224,513.497 239.891,528.497 240.5,543.5C 234.833,543.5 229.167,543.5 223.5,543.5C 224.195,526.674 224.862,509.841 225.5,493C 224.635,476.178 224.302,459.345 224.5,442.5 Z M 241.5,491.5 C 240.065,488.972 239.065,486.305 238.5,483.5C 238.5,472.167 238.5,460.833 238.5,449.5C 247.918,448.155 257.251,448.988 266.5,452C 272.095,455.856 274.762,461.19 274.5,468C 274.195,480.306 268.028,487.806 256,490.5C 251.205,491.358 246.372,491.691 241.5,491.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.878 }}
        fill="#585655"
        d="M 372.5,442.5 C 378.758,442.19 384.924,442.524 391,443.5C 399.413,459.328 408.08,474.994 417,490.5C 426.068,475.088 434.735,459.421 443,443.5C 447.748,442.365 452.581,442.198 457.5,443C 444.763,461.646 432.93,480.813 422,500.5C 421.524,514.839 421.691,529.173 422.5,543.5C 417.167,543.5 411.833,543.5 406.5,543.5C 407.578,529.188 407.745,514.855 407,500.5C 395.239,481.309 383.739,461.976 372.5,442.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.877 }}
        fill="#585655"
        d="M 594.5,442.5 C 612.5,442.5 630.5,442.5 648.5,442.5C 648.5,445.833 648.5,449.167 648.5,452.5C 635.831,451.878 623.164,451.211 610.5,450.5C 610.167,462.5 609.833,474.5 609.5,486.5C 621.187,486.124 632.854,485.457 644.5,484.5C 644.5,488.167 644.5,491.833 644.5,495.5C 632.83,494.864 621.164,494.197 609.5,493.5C 609.477,507.178 609.81,520.844 610.5,534.5C 623.219,534.68 635.885,534.013 648.5,532.5C 648.5,536.167 648.5,539.833 648.5,543.5C 630.5,542.167 612.5,542.167 594.5,543.5C 595.824,509.833 595.824,476.166 594.5,442.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.899 }}
        fill="#585655"
        d="M 768.5,442.5 C 793.833,442.5 819.167,442.5 844.5,442.5C 844.823,446.226 844.489,449.893 843.5,453.5C 833.568,452.172 823.568,451.505 813.5,451.5C 813.297,482.176 813.63,512.842 814.5,543.5C 809.167,543.5 803.833,543.5 798.5,543.5C 799.428,512.847 799.761,482.18 799.5,451.5C 789.432,451.505 779.432,452.172 769.5,453.5C 768.511,449.893 768.177,446.226 768.5,442.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.888 }}
        fill="#585655"
        d="M 1082.5,442.5 C 1107.83,442.5 1133.17,442.5 1158.5,442.5C 1158.5,446.167 1158.5,449.833 1158.5,453.5C 1148.55,452.273 1138.55,451.607 1128.5,451.5C 1126.99,482.203 1127.32,512.87 1129.5,543.5C 1123.83,543.5 1118.17,543.5 1112.5,543.5C 1113.36,512.842 1113.69,482.175 1113.5,451.5C 1103.12,451.646 1092.79,452.313 1082.5,453.5C 1082.5,449.833 1082.5,446.167 1082.5,442.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.891 }}
        fill="#585655"
        d="M 1176.5,442.5 C 1194.17,442.5 1211.83,442.5 1229.5,442.5C 1229.5,445.833 1229.5,449.167 1229.5,452.5C 1217.17,451.833 1204.83,451.167 1192.5,450.5C 1191.58,462.469 1191.25,474.469 1191.5,486.5C 1203.52,486.107 1215.52,485.44 1227.5,484.5C 1227.5,488.167 1227.5,491.833 1227.5,495.5C 1215.52,494.691 1203.52,494.358 1191.5,494.5C 1191.5,507.833 1191.5,521.167 1191.5,534.5C 1204.55,534.701 1217.55,534.034 1230.5,532.5C 1230.5,536.167 1230.5,539.833 1230.5,543.5C 1212.5,542.288 1194.5,542.288 1176.5,543.5C 1177.82,509.829 1177.82,476.162 1176.5,442.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.875 }}
        fill="#585655"
        d="M 1245.5,442.5 C 1251.43,442.192 1257.26,442.525 1263,443.5C 1269.39,470.677 1277.39,497.343 1287,523.5C 1296.46,497.114 1305.13,470.448 1313,443.5C 1317,442.167 1321,442.167 1325,443.5C 1333.63,470.294 1342.63,496.961 1352,523.5C 1360.94,496.744 1369.11,469.744 1376.5,442.5C 1380.88,442.174 1385.22,442.508 1389.5,443.5C 1376.69,476.453 1365.02,509.786 1354.5,543.5C 1350.12,543.886 1345.96,543.219 1342,541.5C 1333.86,515.401 1325.52,489.401 1317,463.5C 1307.13,489.951 1297.96,516.618 1289.5,543.5C 1285.12,543.628 1280.79,543.295 1276.5,542.5C 1266.98,508.931 1256.64,475.597 1245.5,442.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.872 }}
        fill="#585655"
        d="M 1463.5,442.5 C 1469.76,442.19 1475.92,442.524 1482,443.5C 1490.2,459.381 1498.87,475.048 1508,490.5C 1517.29,474.911 1525.79,458.911 1533.5,442.5C 1538.21,442.173 1542.88,442.507 1547.5,443.5C 1535.83,461.5 1524.66,479.833 1514,498.5C 1513.01,501.408 1512.51,504.408 1512.5,507.5C 1513.37,519.484 1513.7,531.484 1513.5,543.5C 1508.17,543.5 1502.83,543.5 1497.5,543.5C 1498.32,529.84 1498.49,516.173 1498,502.5C 1496.86,499.417 1495.53,496.417 1494,493.5C 1483.29,476.743 1473.13,459.743 1463.5,442.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.455 }}
        fill="#929090"
        d="M 238.5,483.5 C 239.065,486.305 240.065,488.972 241.5,491.5C 240.5,491.5 239.5,491.5 238.5,491.5C 238.5,488.833 238.5,486.167 238.5,483.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.568 }}
        fill="#858282"
        d="M 343.5,493.5 C 345.176,494.183 345.843,495.517 345.5,497.5C 335.161,497.666 324.828,497.5 314.5,497C 315.066,496.005 315.733,495.172 316.5,494.5C 320.783,495.827 325.449,496.493 330.5,496.5C 335.167,496.167 339.833,495.833 344.5,495.5C 344.137,494.817 343.804,494.15 343.5,493.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.562 }}
        fill="#878484"
        d="M 1054.5,493.5 C 1055.43,494.568 1055.77,495.901 1055.5,497.5C 1047.32,497.826 1039.32,497.492 1031.5,496.5C 1039.17,496.5 1046.83,496.5 1054.5,496.5C 1054.5,495.5 1054.5,494.5 1054.5,493.5 Z"
      />
    </g>
    <g>
      <path
        style={{ opacity: 0.562 }}
        fill="#868383"
        d="M 1410.5,495.5 C 1419.99,496.494 1429.65,496.827 1439.5,496.5C 1435.42,497.193 1430.58,497.526 1425,497.5C 1419.83,497.167 1414.67,496.833 1409.5,496.5C 1409.62,495.893 1409.96,495.56 1410.5,495.5 Z"
      />
    </g>
  </svg>
);

export default GMOLogo;
