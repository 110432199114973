import * as React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';
import Footer from '../components/Footer';
import Container from '../components/Container';
import Header from '../components/Header';
import BuildRelationshipsSection from '../components/sections/BuildRelationshipsSection';
import CybersourceLogo from '../components/partner-logos/CybersourceLogo';
import WorldpayLogo from '../components/partner-logos/WorldpayLogo';
import PayPalLogo from '../components/partner-logos/PayPalLogo';
import BraintreeLogo from '../components/partner-logos/BraintreeLogo';
import AdyenLogo from '../components/partner-logos/AdyenLogo';
import AllPaymentIntegrationsSection from '../components/sections/AllPaymentIntegrations';
import GMOLogo from '../components/partner-logos/GMOLogo';
import NMILogo from '../components/partner-logos/NMILogo';
import AuthorizeNetLogo from '../components/partner-logos/AuthorizeNetLogo';
import SepaLogo from '../components/partner-logos/SepaLogo';
import DPOLogo from '../components/partner-logos/DPOLogo';
import ApplePayLogo from '../components/partner-logos/ApplePayLogo';

const PartnerCard = ({ className, children }) => (
  <div className={` overflow-hidden min-h-[6rem] px-8 py-2 rounded shadow flex justify-center items-center ${className}`}>
    {children}
  </div>
);

const IntegrationsSection = ({ className, containerClassName, gridClassName, children, title, description }) => (
  <section className={`  ${className}`}>
    <Container className={`my-24 ${containerClassName}`}>
      <h2 className="text-center text-5xl mb-2 font-medium text-black">{title}</h2>
      <p className="text-center text-lg mb-10">{description} </p>
      <div className={`grid gap-4 text-black ${gridClassName}`}>{children}</div>
    </Container>
  </section>
);

const PaymentProvidersPage = () => (
  <Layout>
    <Seo title="Subscribe Pro integrates with your payment provider" />

    <Header graphic={3}>Payment Providers</Header>

    <IntegrationsSection
      containerClassName="lg:px-[120px] mb-4"
      gridClassName="grid-cols-2 md:grid-cols-3 lg:grid-cols-5"
      title="Native Payment Integrations"
      description={<>We offer first class integrations for leading payment providers.</>}
    >
      <PartnerCard>
        <WorldpayLogo />
      </PartnerCard>
      <PartnerCard>
        <PayPalLogo />
      </PartnerCard>
      <PartnerCard>
        <BraintreeLogo />
      </PartnerCard>
      <PartnerCard>
        <AdyenLogo />
      </PartnerCard>
      <PartnerCard>
        <CybersourceLogo />
      </PartnerCard>
      <PartnerCard>
        <GMOLogo />
      </PartnerCard>
      <PartnerCard>
        <NMILogo />
      </PartnerCard>
      <PartnerCard>
        <AuthorizeNetLogo />
      </PartnerCard>
      <PartnerCard>
        <SepaLogo />
      </PartnerCard>
      <PartnerCard>
        <DPOLogo />
      </PartnerCard>
      <PartnerCard>
        <ApplePayLogo />
      </PartnerCard>
    </IntegrationsSection>

    <AllPaymentIntegrationsSection />

    <BuildRelationshipsSection />

    <Footer />
  </Layout>
);

export default PaymentProvidersPage;
