import React from 'react';

// eslint-disable-next-line max-len
const styles = `.cls-1{fill:url(#linear-gradient);}.cls-2{fill:#1c3761;}.cls-3{fill:#1b3760;}.cls-4{fill:url(#linear-gradient-3);}.cls-5{fill:url(#linear-gradient-2);}`;

const DPOLogo = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" {...props} viewBox="0 0 317.22 110.24">
    <defs>
      <style>{styles}</style>
      <linearGradient
        id="linear-gradient"
        x1="-3.55"
        y1="68.4"
        x2="316.48"
        y2="45.61"
        gradientTransform="translate(0 99.56) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ec6669" />
        <stop offset=".08" stopColor="#dc626f" />
        <stop offset=".21" stopColor="#b25982" />
        <stop offset=".34" stopColor="#814f98" />
        <stop offset=".65" stopColor="#283374" />
        <stop offset=".75" stopColor="#376294" />
        <stop offset=".86" stopColor="#4795b6" />
        <stop offset=".95" stopColor="#51b4cb" />
        <stop offset=".99" stopColor="#55c0d3" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="-2.03"
        y1="74.09"
        x2="318"
        y2="51.29"
        gradientTransform="translate(0 99.56) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ec6669" />
        <stop offset=".08" stopColor="#dc626f" />
        <stop offset=".21" stopColor="#b25982" />
        <stop offset=".34" stopColor="#814f98" />
        <stop offset=".65" stopColor="#283374" />
        <stop offset=".75" stopColor="#376294" />
        <stop offset=".86" stopColor="#4795b6" />
        <stop offset=".95" stopColor="#51b4cb" />
        <stop offset=".99" stopColor="#55c0d3" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-3"
        x1="-.95"
        y1="80.63"
        x2="319.08"
        y2="57.83"
        gradientTransform="translate(0 99.56) scale(1 -1)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ec6669" />
        <stop offset=".08" stopColor="#dc626f" />
        <stop offset=".21" stopColor="#b25982" />
        <stop offset=".34" stopColor="#814f98" />
        <stop offset=".65" stopColor="#283374" />
        <stop offset=".75" stopColor="#376294" />
        <stop offset=".86" stopColor="#4795b6" />
        <stop offset=".95" stopColor="#51b4cb" />
        <stop offset=".99" stopColor="#55c0d3" />
      </linearGradient>
    </defs>
    <g id="c">
      <g>
        <path
          className="cls-3"
          d="M96.91,80.58h2.68v10.44h.06c.48-.74,1.16-1.32,2.06-1.74s1.85-.64,2.86-.64c1.09,0,2.08,.19,2.97,.57s1.64,.9,2.27,1.57c.63,.66,1.11,1.45,1.45,2.35,.34,.91,.51,1.89,.51,2.94s-.17,2.03-.51,2.92-.82,1.68-1.45,2.36c-.63,.67-1.38,1.2-2.27,1.58-.88,.38-1.87,.57-2.97,.57-.96,0-1.89-.21-2.79-.63-.91-.42-1.62-1-2.13-1.76h-.06v2.03h-2.68v-22.56Zm7.33,20.4c.72,0,1.36-.12,1.94-.37,.58-.25,1.06-.59,1.46-1.01s.71-.94,.92-1.55,.33-1.27,.33-1.98-.11-1.38-.33-1.98-.53-1.12-.92-1.55-.88-.77-1.46-1.01c-.58-.25-1.22-.37-1.94-.37s-1.36,.12-1.94,.37c-.58,.25-1.07,.59-1.46,1.01-.4,.43-.71,.94-.92,1.55-.22,.61-.33,1.27-.33,1.98s.11,1.38,.33,1.98c.22,.61,.53,1.12,.92,1.55,.4,.43,.88,.76,1.46,1.01s1.22,.37,1.94,.37Z"
        />
        <path
          className="cls-3"
          d="M112.26,88.94h3.1l4.19,11.03h.06l4.01-11.03h2.86l-6.71,17.18c-.24,.62-.49,1.18-.74,1.69s-.57,.94-.92,1.3c-.36,.36-.79,.64-1.29,.83s-1.12,.3-1.85,.3c-.4,0-.8-.02-1.2-.08-.41-.05-.8-.15-1.17-.31l.33-2.44c.53,.22,1.07,.33,1.6,.33,.41,0,.77-.06,1.05-.17s.53-.27,.74-.48c.21-.21,.38-.45,.52-.73,.14-.28,.28-.6,.41-.96l.87-2.23-5.85-14.22h0Z"
        />
        <g>
          <path
            className="cls-3"
            d="M161.9,96.04v.48c0,.18,0,.33-.01,.48h-11.55c.01,.61,.15,1.18,.42,1.73,.27,.55,.6,1,1.03,1.4,.43,.39,.92,.7,1.49,.94,.57,.22,1.16,.33,1.79,.33,.98,0,1.83-.22,2.56-.64,.71-.43,1.27-.97,1.68-1.58l2.02,1.62c-.79,1.03-1.68,1.79-2.75,2.28-1.04,.48-2.23,.71-3.51,.71-1.12,0-2.11-.18-3.05-.55s-1.74-.88-2.43-1.55c-.67-.66-1.21-1.46-1.59-2.4s-.6-1.98-.6-3.13,.19-2.16,.57-3.13,.92-1.77,1.58-2.44c.67-.67,1.49-1.21,2.4-1.58,.92-.37,1.92-.57,2.96-.57s2.02,.18,2.9,.52,1.62,.85,2.2,1.5c.61,.66,1.07,1.44,1.4,2.38,.36,.98,.51,2.02,.51,3.19h-.02Zm-2.93-1.15c-.01-.57-.1-1.12-.28-1.62s-.43-.94-.76-1.31-.76-.66-1.27-.88-1.09-.33-1.77-.33c-.61,0-1.21,.1-1.73,.33-.55,.22-1,.51-1.41,.88-.39,.37-.71,.8-.98,1.31-.27,.51-.42,1.04-.43,1.62h8.63Z"
          />
          <polygon
            className="cls-3"
            points="191.01 88.89 187.72 100.1 184.16 88.89 181.12 88.89 177.62 100.1 174.3 88.89 171.18 88.89 176.03 103.44 179.02 103.44 182.58 92.53 182.64 92.53 186.23 103.44 189.24 103.44 194.04 88.89 191.01 88.89"
          />
          <path
            className="cls-3"
            d="M209.44,96.13c0,1.13-.19,2.16-.6,3.1-.39,.94-.97,1.74-1.65,2.44-.7,.67-1.53,1.22-2.49,1.59-.97,.37-1.98,.57-3.1,.57s-2.14-.19-3.1-.57c-.97-.37-1.79-.92-2.49-1.59s-1.25-1.5-1.64-2.44-.6-1.97-.6-3.1,.19-2.16,.6-3.08c.39-.94,.94-1.73,1.64-2.4s1.53-1.21,2.49-1.58c.97-.37,1.98-.57,3.1-.57s2.14,.19,3.1,.57c.97,.37,1.79,.89,2.49,1.58,.7,.68,1.27,1.49,1.65,2.4,.4,.94,.6,1.97,.6,3.08Zm-3.01,.01c0-.7-.1-1.35-.33-1.98-.22-.64-.52-1.18-.94-1.65-.42-.48-.92-.85-1.53-1.16-.61-.28-1.3-.43-2.07-.43s-1.46,.15-2.07,.43-1.12,.67-1.53,1.16-.71,1.03-.94,1.65c-.22,.64-.33,1.3-.33,1.98s.1,1.35,.33,1.98,.52,1.21,.94,1.68,.92,.88,1.53,1.16,1.3,.43,2.07,.43,1.46-.15,2.07-.43,1.12-.67,1.53-1.16c.42-.48,.71-1.04,.94-1.68,.24-.63,.33-1.28,.33-1.98Z"
          />
          <rect className="cls-3" x="220.53" y="84.4" width="2.89" height="19.04" />
          <path
            className="cls-3"
            d="M170.03,91.1v-2.2h-3.63v-4.5h-2.86v14.74c0,1.5,.37,2.64,1.09,3.41,.71,.76,1.77,1.13,3.14,1.13,.46,0,.88-.04,1.31-.1,.34-.06,.66-.13,.94-.24v-2.38c-.22,.09-.46,.15-.71,.22-.31,.06-.6,.09-.85,.09-.71,0-1.25-.19-1.58-.57s-.48-1-.48-1.88v-7.71h3.63Z"
          />
          <path
            className="cls-3"
            d="M218.09,88.51c-.98,0-1.86,.27-2.64,.79-.79,.52-1.49,1.21-1.88,2.07v-2.47h-2.72v14.54h2.89v-7.64c0-.61,.09-1.21,.24-1.77,.15-.57,.42-1.04,.74-1.49,.33-.43,.76-.76,1.27-1.03,.51-.27,1.12-.39,1.82-.39,.46,0,.89,.04,1.31,.15v-2.68c-.3-.07-.63-.09-1.03-.09h0Z"
          />
          <path
            className="cls-3"
            d="M145.54,92.11c-.22-.71-.55-1.32-1-1.88-.46-.52-1-.97-1.68-1.27-.67-.31-1.49-.48-2.43-.48-.52,0-1.04,.06-1.53,.22-.48,.15-.94,.34-1.35,.6-.42,.27-.76,.55-1.09,.89-.33,.33-.66,.7-.85,1.09h0v-2.38h-2.71v14.54h2.89v-7.73c0-1.44,.37-2.62,1.09-3.47,.71-.88,1.65-1.31,2.8-1.31,.67,0,1.22,.13,1.65,.37,.43,.24,.76,.57,1,.98s.42,.88,.51,1.41c.09,.52,.13,1.09,.13,1.68v8.05h2.9v-9.02c0-.83-.1-1.61-.33-2.32v.03Z"
          />
          <polygon
            className="cls-3"
            points="229.55 88.89 233.11 88.89 226.97 96.22 233.02 103.44 229.46 103.44 223.42 96.22 229.55 88.89"
          />
        </g>
      </g>
      <g>
        <path
          className="cls-1"
          d="M0,65.14V.45H37.46c4.57,0,8.78,.77,12.64,2.31,3.85,1.54,7.18,3.71,9.97,6.5,2.79,2.79,4.97,6.13,6.54,10.01,1.57,3.89,2.36,8.17,2.36,12.86s-.79,9.05-2.36,13.08-3.75,7.52-6.54,10.46-6.11,5.25-9.97,6.94-8.07,2.54-12.64,2.54H0Zm37.46-11.31c2.91,0,5.59-.53,8.05-1.6s4.6-2.54,6.41-4.41c1.81-1.87,3.22-4.11,4.23-6.72s1.51-5.43,1.51-8.45-.5-5.83-1.51-8.41-2.42-4.79-4.23-6.63c-1.81-1.84-3.95-3.28-6.41-4.32-2.46-1.04-5.15-1.56-8.05-1.56H11.21V53.83h26.25Z"
        />
        <path
          className="cls-5"
          d="M96.77,47.16v-10.95h25.98c3.97,0,7.07-1.13,9.3-3.38,2.22-2.25,3.34-5.22,3.34-8.9s-1.11-6.7-3.34-8.9c-2.23-2.19-5.33-3.29-9.3-3.29h-34.26v53.39h-11.21V.44h45.47c3.74,0,7.07,.55,10.01,1.64,2.94,1.1,5.41,2.64,7.43,4.63s3.56,4.41,4.63,7.25c1.07,2.85,1.6,6.05,1.6,9.61s-.53,6.69-1.6,9.57c-1.07,2.88-2.61,5.35-4.63,7.43-2.02,2.08-4.49,3.69-7.43,4.85s-6.27,1.73-10.01,1.73h-25.98Z"
        />
        <path
          className="cls-4"
          d="M185.66,65.14c-4.51,0-8.67-.84-12.5-2.54-3.83-1.69-7.15-4-9.96-6.94-2.82-2.94-5.02-6.42-6.59-10.46-1.57-4.03-2.36-8.39-2.36-13.08s.78-9.08,2.36-12.99c1.57-3.92,3.77-7.25,6.59-10.01,2.81-2.76,6.14-4.89,9.96-6.41,3.83-1.51,7.99-2.27,12.5-2.27h15.75c4.69,0,8.97,.77,12.86,2.31,3.88,1.54,7.22,3.71,10.01,6.5s4.95,6.13,6.49,10.01c1.54,3.89,2.31,8.17,2.31,12.86s-.79,9.05-2.36,13.08c-1.57,4.03-3.77,7.52-6.58,10.46-2.82,2.94-6.15,5.25-10.01,6.94-3.86,1.69-8.1,2.54-12.72,2.54h-15.75Zm15.75-11.3c2.96,0,5.69-.53,8.19-1.6,2.49-1.07,4.64-2.54,6.45-4.41,1.81-1.87,3.22-4.11,4.23-6.72s1.51-5.43,1.51-8.45-.5-5.83-1.51-8.41-2.42-4.79-4.23-6.63c-1.81-1.84-3.96-3.28-6.45-4.32-2.49-1.04-5.22-1.56-8.19-1.56h-15.75c-2.91,0-5.59,.52-8.05,1.56s-4.58,2.48-6.36,4.32c-1.78,1.84-3.17,4.03-4.18,6.58-1.01,2.55-1.51,5.37-1.51,8.45s.5,5.84,1.51,8.45,2.4,4.85,4.18,6.72,3.9,3.34,6.36,4.41,5.15,1.6,8.05,1.6h15.75Z"
        />
      </g>
      <g>
        <path
          className="cls-2"
          d="M264.18,12c0,7.71-4.75,12.1-11.18,12.1-4.29,0-7.61-2.04-8.99-5.36v14.65h-2.91V.46h2.81V5.41c1.63-3.37,5.11-5.41,9.19-5.41,6.59,0,11.08,4.24,11.08,12h0Zm-3.17,0c.1-5.97-3.47-9.19-8.53-9.19-4.44,0-8.42,2.65-8.42,8.68v.51c0,6.08,3.47,9.34,8.42,9.34s8.48-3.37,8.53-9.34h0Z"
        />
        <path
          className="cls-2"
          d="M291.03,.46V23.64h-2.91v-4.54c-1.63,3.06-5.11,5-9.09,5-6.69,0-11.18-4.29-11.18-12s4.75-12.1,11.28-12.1c4.29,0,7.76,2.04,9.14,5.51V.46h2.76Zm-3.11,11.64c0-6.18-3.52-9.29-8.42-9.29s-8.42,3.32-8.53,9.29c-.1,5.97,3.52,9.19,8.53,9.19,4.39,0,8.42-2.65,8.42-8.68v-.51Z"
        />
        <path className="cls-2" d="M317.22,.41l-15.32,32.98h-3.22l5.21-10.98L294.75,.46h3.57l7.25,18.48L313.74,.41h3.48Z" />
      </g>
    </g>
  </svg>
);

export default DPOLogo;
